.login-background {
  background: linear-gradient(to bottom right, #083f88, #d1ab3e);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

